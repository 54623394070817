import React from 'react';

export default function InstagramIcon(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.8125 0.5H12.1875C15.1211 0.5 17.5 2.87894 17.5 5.8125V12.1875C17.5 15.1211 15.1211 17.5 12.1875 17.5H5.8125C2.87894 17.5 0.5 15.1211 0.5 12.1875V5.8125C0.5 2.87894 2.87894 0.5 5.8125 0.5ZM12.1875 15.9062C14.2381 15.9062 15.9062 14.2381 15.9062 12.1875V5.8125C15.9062 3.76187 14.2381 2.09375 12.1875 2.09375H5.8125C3.76187 2.09375 2.09375 3.76187 2.09375 5.8125V12.1875C2.09375 14.2381 3.76187 15.9062 5.8125 15.9062H12.1875Z" fill="#141414"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.75 9C4.75 6.65294 6.65294 4.75 9 4.75C11.3471 4.75 13.25 6.65294 13.25 9C13.25 11.3471 11.3471 13.25 9 13.25C6.65294 13.25 4.75 11.3471 4.75 9ZM6.34375 9C6.34375 10.4641 7.53587 11.6562 9 11.6562C10.4641 11.6562 11.6562 10.4641 11.6562 9C11.6562 7.53481 10.4641 6.34375 9 6.34375C7.53587 6.34375 6.34375 7.53481 6.34375 9Z" fill="#141414"/>
            <path d="M13.6042 5.45837C14.191 5.45837 14.6667 4.98268 14.6667 4.39587C14.6667 3.80907 14.191 3.33337 13.6042 3.33337C13.0174 3.33337 12.5417 3.80907 12.5417 4.39587C12.5417 4.98268 13.0174 5.45837 13.6042 5.45837Z" fill="#141414"/>
        </svg>
    );
}