export default (state = {
    language: 'ua',
    windowWidth: window.innerWidth
}, action) => {

    switch (action.type) {

        case 'OPTIONS_LANGUAGE_SET':
            return {...state, language: action.payload};
        case 'OPTIONS_WINDOWWIDTH_SET':
            return {
                ...state,
                windowWidth: window.innerWidth > window.screen.availWidth ?
                    window.screen.availWidth : window.innerWidth
            };

        default:
            return state;

    }
}