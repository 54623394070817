import React from 'react';
import Slider from "./Slider";

class SliderWrapper extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <React.Fragment>
                <div className="SliderWrapper d-none d-md-block">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8">
                                <Slider sliderId="CustomSlider" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="SliderWrapper d-md-none">
                    <Slider sliderId="CustomSliderMobile"/>
                </div>
            </React.Fragment>
        );
    }
}

export default SliderWrapper;