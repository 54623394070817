import React from 'react';
import axios from "axios";
import {withRouter} from "react-router-dom";
import getApiBaseUrlWithLang from "../utility/getApiBaseUrlWithLang";
import Spinner from "../utility/Spinner";
import finish_image from "../images/finish.png";


class OrderStatusModal extends React.Component {
    constructor(props) {
        super(props);

        this.modalShown = false;

        this.state = {
            orderStatus: null,
            loading: false,
            error: null
        };

        this.fetchOrderStatus = this.fetchOrderStatus.bind(this);
        this.renderPaymentSuccess = this.renderPaymentSuccess.bind(this);
        this.hideOrderModal = this.hideOrderModal.bind(this);

    }

    componentDidMount() {

        const routeOrderId = this.props.match.params.id;

        const order_id = window.localStorage.getItem('order_id');

        if (!order_id || !routeOrderId || order_id !== routeOrderId) {
            this.props.history.push("/");
            return;
        }

        this.fetchOrderStatus(order_id);
    }

    renderModalBody() {
        if (this.state.loading) {
            return (
                <div className="text-center" style={{marginTop: '200px'}}>
                    <Spinner/>
                </div>
            );
        } else if (this.state.error) {
            return (
                <div className="text-center">
                    {this.props.error}
                </div>
            );
        } else if (this.state.orderStatus === false) {
            return "";
        } else if (this.state.orderStatus === true) {

            if (this.modalShown === false) {
                localStorage.removeItem('order_id');

                window.setTimeout(function () {
                    window.$("#OrderStatusModal").modal('show');
                }, 500);

                this.modalShown = true;
            }

            return this.renderPaymentSuccess();
        }
    }

    renderPaymentSuccess() {
        return (
            <div className="modal-body">
                <img className="success-image" src={finish_image} alt=""/>
                <div className="greeting">Вітаємо!</div>
                <div className="payment-success">
                    Ваша оплата пройшла успішно!
                </div>
                <div className="text">Ліцензійний ключ був відправлений на вказаний Email.</div>
                <div className="button-block">
                    <button className="btn custom-button" onClick={this.hideOrderModal} data-toggle="modal"
                            data-target="#MainModal">Використати ключ
                    </button>
                </div>
            </div>
        );
    }

    fetchOrderStatus(order_id) {

        this.setState({loading: true});

        axios.post(`${getApiBaseUrlWithLang()}/orders/status`, {order_id: order_id})
            .then((res) => {

                if (res.data.success === true) {
                    this.setState({
                        orderStatus: res.data.data.status,
                        loading: false,
                        error: null
                    });
                } else if (res.data.success === false) {
                    this.setState({error: "Помилка серверу", loading: false});
                }
            })
            .catch((error) => {
                this.setState({loading: false, error: "Помилка серверу"});
            });
    }

    hideOrderModal() {
        window.$("#OrderStatusModal").modal("hide");
    }

    render() {
        return (
            <div className="OrderStatusModal">
                <div className="container">
                    <div className="modal fade" id="OrderStatusModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                             role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {this.renderModalBody()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(OrderStatusModal);