import React from 'react';
import {AnimatePresence, motion} from "framer-motion";
import SliderWrapper from "./SliderWrapper";
import HeaderBusiness from "./HeaderBusiness";
import Goals from "./Goals";
import BestAssistant from "./BestAssistant";
import StepMap from "./StepMap";
import Video from "./Video";
import LetterToUs from "./LetterToUs";
import Footer from "./Footer";
import MainModal from "./MainModal";
import OrderModal from "./OrderModal";
import FeedBackModal from "./FeedBackModal";
import LicenseAgreementModal from "./LicenseAgreementModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import DisclaimerModal from "./DisclaimerModal";
import MailConsentModal from "./MailConsentModal";

class MainPage extends React.Component {
    constructor(props) {
        super(props);


        this.renderSliderWrapper = this.renderSliderWrapper.bind(this);
    }

    renderSliderWrapper() {
        if (this.props.optionsState.windowWidth < 768) {
            return (
                <SliderWrapper
                    optionsState={this.props.optionsState}
                    contentState={this.props.contentState}
                />);
        }
        return null;
    }

    render() {
        return (
            <AnimatePresence>
                <motion.div key="jhjhjh"
                            animate={{opacity: 1}}
                            initial={{opacity: 0}}
                            transition={{duration: 0.5, delay: 0.2}}
                            exit={{opacity: 0}}>
                    <div className="" style={{}}>
                        {/*<Header optionsState={this.props.optionsState} contentState={this.props.contentState}/>*/}
                        <HeaderBusiness optionsState={this.props.optionsState} contentState={this.props.contentState}/>
                        <Goals optionsState={this.props.optionsState} contentState={this.props.contentState}/>
                        <BestAssistant optionsState={this.props.optionsState} contentState={this.props.contentState}/>
                        <StepMap optionsState={this.props.optionsState} contentState={this.props.contentState}/>
                        <Video optionsState={this.props.optionsState} contentState={this.props.contentState}/>
                        {this.renderSliderWrapper()}
                        <LetterToUs optionsState={this.props.optionsState} contentState={this.props.contentState}/>
                        <Footer optionsState={this.props.optionsState} contentState={this.props.contentState}/>
                        <MainModal
                            optionsState={this.props.optionsState}
                            contentState={this.props.contentState}
                            authState={this.props.authState}
                        />
                        <OrderModal
                            optionsState={this.props.optionsState}
                        />
                        <FeedBackModal authState={this.props.authState}/>
                        <LicenseAgreementModal/>
                        <PrivacyPolicyModal/>
                        <DisclaimerModal/>
                        <MailConsentModal/>
                        {this.props.children}
                    </div>
                </motion.div>
            </AnimatePresence>
        );
    }

}

export default MainPage;