import React from 'react';
import arrow from "../images/arrow.svg";
import VideoModal from "./VideoModal";
import SliderWrapper from "./SliderWrapper";

class Video extends React.Component {
    constructor(props) {
        super(props);

        this.state = {showVideo: false};
        
        this.changeShowVideo = this.changeShowVideo.bind(this);
        this.renderSliderWrapper = this.renderSliderWrapper.bind(this);
    }


    changeShowVideo(){
        this.setState({showVideo: !this.state.showVideo});
    }

    renderSliderWrapper(){
        if(this.props.optionsState.windowWidth >= 768){
            return (
                <SliderWrapper
                    optionsState={this.props.optionsState}
                    contentState={this.props.contentState}
                />);
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <div className="Video">
                    <div className="overlay"></div>
                    <div className="content-wrapper">
                        <div className="button-wrapper">
                            <div className="play-button" data-toggle="modal"
                                 data-target="#VideoModal" onClick={this.changeShowVideo}>
                                <img src={arrow} alt=""/>
                            </div>
                        </div>
                        <div className="title">Як роботає сервіс votu.com.ua?</div>
                        <div className="second-title">
                            Результати роботи юридичного онлайн-сервісу
                        </div>
                    </div>
                    {this.renderSliderWrapper()}
                </div>
                <VideoModal show={this.state.showVideo} changeShowVideo={this.changeShowVideo} optionsState={this.props.optionsState} contentState={this.props.contentState} />
            </React.Fragment>
        );
    }

}

export default Video;