export default (state = {
    reviews: null,
    loading: false,
    error: null
}, action) => {

    switch (action.type) {

        case 'REVIEWS_FETCH_START':
            return { ...state, loading: true, error: null };
        case 'REVIEWS_FETCH_SUCCESS':
            return { ...state, reviews: action.payload, loading: false, error: null };
        case 'REVIEWS_FETCH_FAIL':
            return { ...state, reviews: null, loading: false, error: action.payload};

        default:
            return state;

    }
}