import React from 'react';
import BackButton from "../utility/BackButton";
import OrderModalLicense from "./OrderModalLicense";
import OrderModalForm from "./OrderModalForm";
import axios from "axios";
import getApiBaseUrlWithLang from "../utility/getApiBaseUrlWithLang";
import Payment from "./Payment";


class OrderModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            licenseForm: {licenses: [], selectedLicense: "", loading: false, error: null},
            orderForm: {
                formFields: {
                    name: '',
                    email: '',
                    phone: '',
                    sendEmail: true,
                    sendSms: false,
                    license_agreement: "false"

                },
                submitLoading: false,
                submitErrors: null
            },
            payment: {}
        };

        this.setToState = this.setToState.bind(this);
        this.setToStateFormField = this.setToStateFormField.bind(this);
        this.renderNextButton = this.renderNextButton.bind(this);
        this.getNextButtonStatus = this.getNextButtonStatus.bind(this);
        this.getNextButtonCssClasses = this.getNextButtonCssClasses.bind(this);
        this.nextButtonClickHandler = this.nextButtonClickHandler.bind(this);
        this.renderBackButton = this.renderBackButton.bind(this);
        this.getStepComponent = this.getStepComponent.bind(this);
        this.renderModalBody = this.renderModalBody.bind(this);
        this.handleChangeSelectedLicense = this.handleChangeSelectedLicense.bind(this);
        this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
        this.getSubmitObj = this.getSubmitObj.bind(this);

        this.fetchLicenses = this.fetchLicenses.bind(this);
        this.submitFormHandler = this.submitFormHandler.bind(this);

    }

    setToState(firstLevel, valueObj, changeStep, callback) {
        this.setState((prevState) => {
            return {
                ...prevState,
                step: (() => {
                    if (!changeStep) {
                        return prevState.step;
                    } else {
                        if (changeStep === "+1") {
                            return prevState.step + 1;
                        } else if (changeStep === "-1") {
                            return prevState.step - 1;
                        }
                    }
                })(),
                [firstLevel]: {
                    ...prevState[firstLevel],
                    ...valueObj
                }
            }
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    handleChangeSelectedLicense(value) {
        this.setToState("licenseForm", {selectedLicense: value});
    }

    fetchLicenses() {

        this.setToState("licenseForm", {loading: true, error: null});

        axios.get(`${getApiBaseUrlWithLang()}/licenses`)
            .then((res) => {
                if (res.data.success === true) {
                    this.setToState("licenseForm",
                        {licenses: res.data.data, loading: false, error: null}
                    );
                } else if (res.data.success === false) {
                    this.setToState("licenseForm",
                        {licenses: [], loading: false, error: "Помилка серверу"}
                    );
                }
            })
            .catch((error) => {
                this.setToState("licenseForm",
                    {licenses: [], loading: false, error: "Помилка серверу"}
                );
            });
    }

    renderNextButton() {
        if (this.state.step < 3) {
            return (
                <button className={this.getNextButtonCssClasses()}
                        onClick={() => {
                            this.nextButtonClickHandler()
                        }}
                        disabled={!this.getNextButtonStatus()}
                >
                    {this.getNextButtonText()}
                </button>);
        }
        return null;
    }

    getNextButtonStatus() {
        if (this.state.step === 1) {
            return this.state.licenseForm.selectedLicense !== "";
        } else if (this.state.step === 2) {
            if (this.state.orderForm.submitLoading) {
                return false;
            }
            return true;
        }
        return false;
    }

    getNextButtonText() {

        if (this.state.step === 2) {
            if (this.state.orderForm.submitLoading) {
                return (<i className='fa fa-spinner fa-pulse fa-1x fa-fw'></i>);
            }
            return "Перейти до оплати";
        }
        return "Далі";
    }

    getNextButtonCssClasses() {
        if (this.state.step === 2 || this.state.step === 3) {
            return "btn customButtonNext step";
        }
        return "btn customButtonNext";
    }

    nextButtonClickHandler() {
        if (this.state.step === 1) {
            this.setState({step: 2});
        } else if (this.state.step === 2) {
            this.submitFormHandler();
        }
    }

    renderBackButton() {
        if (this.state.step === 2) {
            return (<BackButton handleBackButtonClick={this.handleBackButtonClick}/>);
        }
        return null;
    }

    handleBackButtonClick() {
        this.setState({step: 1});
    }

    renderModalBody() {
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        {this.getStepComponent()}
                    </div>
                </div>

            </div>
        );
    }

    getStepComponent() {

        switch (this.state.step) {

            case 1:
                return (
                    <OrderModalLicense
                        licenseForm={this.state.licenseForm}
                        fetchLicenses={this.fetchLicenses}
                        handleChangeSelectedLicense={this.handleChangeSelectedLicense}
                    />);
            case 2:
                return (
                    <OrderModalForm
                        orderForm={this.state.orderForm}
                        licenseForm={this.state.licenseForm}
                        setToStateFormField={this.setToStateFormField}
                    />);
            case 3:
                return (
                    <Payment
                        data={this.state.payment.data}
                        signature={this.state.payment.signature}
                    />
                );
            default:
                return null;
        }
    }

    getSubmitObj() {
        const formFields = this.state.orderForm.formFields;

        const formattedPhone = formFields['phone'].replace(/ |_/g, '');

        return {
            ...formFields,
            phone: formattedPhone,
            license_id: parseInt(this.state.licenseForm.selectedLicense)
        };
    }

    submitFormHandler() {

        if (!this.checkForLicenseAgreementConsent()) return;

        this.setToState("orderForm", {submitLoading: true, submitError: null});

        axios.post(`${getApiBaseUrlWithLang()}/orders`, this.getSubmitObj())
            .then((res) => {
                if (res.data.success === true) {

                    window.localStorage.setItem('order_id', res.data.data.order_id);

                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            step: 3,
                            orderForm: {...prevState.orderForm, submitLoading: false},
                            payment: {data: res.data.data.data, signature: res.data.data.signature}
                        };
                    });

                } else if (res.data.success === false && res.data.data.errors) {
                    this.setToState(
                        "orderForm",
                        {submitErrors: res.data.data.errors, submitLoading: false},
                        false,
                        () => {
                            // this.scrollTopErrorIntoView()
                        }
                    );
                }
            })
            .catch((error) => {
                //this.setToState("login", {error: "Ошибка сервера", loading: false});
                this.setToState("orderForm", {submitLoading: false});
            });
    }

    checkForLicenseAgreementConsent() {
        const formFields = this.state.orderForm.formFields;

        if (formFields.license_agreement === 'true') {
            return true;
        }

        const errorText = this.props.optionsState.language === "ua" ?
            "Вам потрібно прийняти умови угоди" :
            "Вам необходимо принять условия соглашения";

        this.setToState(
            'orderForm',
            {submitErrors: {license_agreement: [errorText]}},
            false,
            () => {
                //this.scrollTopErrorIntoView()
            });

        return false;
    }

    setToStateFormField(name, newVal) {
        this.setState((prevState) => {

            const formFieldsUpd = {...prevState.orderForm.formFields, [name]: newVal};

            let submitErrorsUpd = null;

            if (prevState.orderForm.submitErrors) {
                submitErrorsUpd = {...prevState.orderForm.submitErrors};
                delete submitErrorsUpd[name];
            }

            return {
                ...prevState,
                orderForm: {...prevState.orderForm, formFields: formFieldsUpd, submitErrors: submitErrorsUpd}
            };
        });
    }

    render() {
        return (
            <div className="OrderModal">
                <div className="container">
                    <div className="modal fade" id="OrderModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                             role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {this.renderBackButton()}
                                    <h5 className="modal-title"></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {this.renderModalBody()}
                                <div className="button-block">
                                    {this.renderNextButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default OrderModal;