import React from 'react';
import header_business from "../images/header_business.jpg";

class HeaderBusiness extends React.Component {
    constructor(props){
        super(props);

    }

    render(){
        return (
            <div className="HeaderBusiness">
                <div className="filter"></div>
                <div className="container content-wrapper">
                    <div className="votu-text">
                        <div className="first">VOTU</div>
                        <div className="second">com.ua</div>
                    </div>
                    <div className="help-text">
                        <div>Ми допоможемо <span>Вам</span><span className="yellow-text"> з</span></div>
                        {/*<div><span>Вам</span><span className="yellow-text"> з</span></div>*/}
                        <div className="yellow-text">поверненням товару</div>
                    </div>
                    <div className="reassurance-block">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-md-8">
                                Запевняємо Вас - Ви будете здивовані реакцією порушника Ваших прав на підготовлений документ нашим автоматизованим юридичним онлайн-сервісом!
                            </div>
                        </div>
                    </div>
                    <div className="button-block">
                        <button className="btn custom-button" data-toggle="modal" data-target="#MainModal">Отримати рішення</button>
                    </div>
                </div>
            </div>);
    }
}

export default HeaderBusiness;