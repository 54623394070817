import React from 'react';
import assistant from "../images/assistant.svg";
import assistant_icon_01 from "../images/assistant_icon_01.svg";
import assistant_icon_02 from "../images/assistant_icon_02.svg";
import assistant_icon_03 from "../images/assistant_icon_03.svg";

class BestAssistant extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="BestAssistant">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="title">
                                        Чому юридичний онлайн-сервіс - це найкращий помічник?
                                    </div>
                                </div>
                            </div>

                            <div className="row d-none d-md-flex">
                                <div className="col-md-6">
                                    <div className="icon-block">
                                        <div className="icon-img">
                                            <img src={assistant_icon_01} alt=""/>
                                        </div>
                                        <div className="icon-text">
                                            В інтелект ЮОС закладений БАГАТОРІЧНИЙ ДОСВІД професійних правників України!
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="icon-block">
                                        <div className="icon-img">
                                            <img src={assistant_icon_02} alt=""/>
                                        </div>
                                        <div className="icon-text">
                                            ЮОС працює завжди: 24 години, 7 днів на тиждень. В будь-який зручний для Вас
                                            час він готовий допомогти Вам!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center d-none d-md-flex">
                                <div className="col-md-6">
                                    <div className="icon-block">
                                        <div className="icon-img">
                                            <img src={assistant_icon_03} alt=""/>
                                        </div>
                                        <div className="icon-text">
                                            Завдяки ЮОС більше не потрібно оплачувати дорогі послуги юристів!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6">
                            <div className="image-wrapper">
                                <img src={assistant} style={{}}/>
                            </div>
                        </div>

                    </div>

                    <div className="row d-flex d-md-none">

                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="icon-block">
                                    <div className="icon-img">
                                        <img src={assistant_icon_01} alt=""/>
                                    </div>
                                    <div className="icon-text">
                                        В інтелект ЮОС закладений БАГАТОРІЧНИЙ ДОСВІД професійних правників України!
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="icon-block">
                                        <div className="icon-img">
                                            <img src={assistant_icon_02} alt=""/>
                                        </div>
                                        <div className="icon-text">
                                            ЮОС працює завжди: 24 години, 7 днів на тиждень. В будь-який зручний для Вас
                                            час він
                                            готовий допомогти Вам!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="icon-block">
                                        <div className="icon-img">
                                            <img src={assistant_icon_03} alt=""/>
                                        </div>
                                        <div className="icon-text">
                                            Завдяки ЮОС більше не потрібно оплачувати дорогі послуги юристів!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BestAssistant;