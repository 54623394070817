import React from 'react';
import jQuery from 'jquery';
import {Route, Link, Switch, withRouter, Redirect} from "react-router-dom";
import Popper from 'popper.js';
import bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {connect} from 'react-redux';
import './app.scss';
import {fetchContent, fetchReviews, windowWidthSet} from '../actions';
import MainPage from "./MainPage";
import OrderStatusModal from "./OrderStatusModal";

window.$ = window.jQuery = jQuery;

class App extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        const currentLanguage = this.props.optionsState.language;

        // this.props.fetchContent(currentLanguage);
        // this.props.fetchReviews(currentLanguage);

        window.addEventListener('resize', this.props.windowWidthSet);
    }

    render() {

        return (
            <Switch>
                <Route exact path="/order/:id" render={() => {
                    return (<MainPage
                        optionsState={this.props.optionsState}
                        contentState={this.props.contentState}
                        authState={this.props.authState}>
                        <OrderStatusModal/>
                    </MainPage>);
                }}/>
                <Route exact path="/" render={() => {
                    return (<MainPage
                        optionsState={this.props.optionsState}
                        contentState={this.props.contentState}
                        authState={this.props.authState}/>);
                }}/>
                <Redirect to="/"/>
            </Switch>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        optionsState: state.optionsState,
        contentState: state.contentState,
        authState: state.authState
    }
};

export default connect(mapStateToProps, {fetchContent, fetchReviews, windowWidthSet})(App);
