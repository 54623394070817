import React from 'react';
import {AnimatePresence, motion} from "framer-motion";

class OrderModalLicense extends React.Component {
    constructor(props) {
        super(props);

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectChangeByClick = this.handleSelectChangeByClick.bind(this);
        this.getLicenseTitle = this.getLicenseTitle.bind(this);
        this.renderCheckBoxes = this.renderCheckBoxes.bind(this);
    }

    componentDidMount() {
        if (this.props.licenseForm.licenses.length === 0) {
            this.props.fetchLicenses();
        }

        //window.$("#OrderModal").modal('show');
    }

    getLicenseTitle(license) {
        return (
            <React.Fragment>
                <span className="license-name">{license.name}</span>
                <span className="license-duration">({license.duration_days} днів):</span>
                <span className="license-price">{license.price} грн</span>
            </React.Fragment>
        );
    }

    renderCheckBoxes() {

        return this.props.licenseForm.licenses.map((license, i) => {
            return (
                <div className="form-check" key={i}>
                    <input className="form-check-input" type="checkbox"
                           checked={parseInt(this.props.licenseForm.selectedLicense) === license.id}
                           onChange={this.handleSelectChange} value={license.id}/>
                    <label data-id={license.id}
                           className={`form-check-label ${parseInt(this.props.licenseForm.selectedLicense) === license.id ? 'active' : ''}`}
                           onClick={this.handleSelectChangeByClick}
                    >
                        {this.getLicenseTitle(license)}
                    </label>
                </div>
            );
        });
    }

    handleSelectChange(e) {
        if (this.props.licenseForm.selectedLicense !== e.target.value) {
            this.props.handleChangeSelectedLicense(e.target.value);
        } else {
            this.props.handleChangeSelectedLicense("");
        }
    }

    handleSelectChangeByClick(e) {

        if (this.props.licenseForm.selectedLicense !== e.currentTarget.getAttribute('data-id')) {
            this.props.handleChangeSelectedLicense(e.currentTarget.getAttribute('data-id'));
        } else {
            this.props.handleChangeSelectedLicense("");
        }
    }

    render() {
        return (
            <AnimatePresence>
                <motion.div key="jhzdsjfidghasef"
                            animate={{opacity: 1}}
                            initial={{opacity: 0}}
                            transition={{duration: 0.5}}
                            exit={{opacity: 0}}>
                    <div className="OrderModalLicense">
                        <div className="title">Оберіть ліцензію</div>
                        {this.renderCheckBoxes()}
                    </div>
                </motion.div>
            </AnimatePresence>
        );
    }

}

export default OrderModalLicense;