import React from 'react';
import {motion, AnimatePresence} from "framer-motion";
import Spinner from "../utility/Spinner";

class MainModalBlockChoose extends React.Component {

    constructor(props) {
        super(props);

        this.renderCheckBoxes = this.renderCheckBoxes.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectChangeByClick = this.handleSelectChangeByClick.bind(this);
        this.renderIssuesBlock = this.renderIssuesBlock.bind(this);
    }

    renderCheckBoxes() {
        return this.props.issueOptions.map((issueOption, i) => {
            return (
                <div className="form-check" key={i}>
                    <input className="form-check-input" type="checkbox"
                           checked={parseInt(this.props.selectedIssue) === issueOption.id}
                           onChange={this.handleSelectChange} value={issueOption.id}/>
                    <label data-id={issueOption.id}
                           className={`form-check-label ${parseInt(this.props.selectedIssue) === issueOption.id ? 'active' : ''}`}
                           onClick={this.handleSelectChangeByClick}
                    >
                        {issueOption.title}
                    </label>
                </div>
            );
        });
    }

    handleSelectChange(e) {
        if (this.props.selectedIssue !== e.target.value) {
            this.props.handleChangeSelectedIssue(e.target.value);
        } else {
            this.props.handleChangeSelectedIssue("");
        }
    }

    handleSelectChangeByClick(e) {

        if (this.props.selectedIssue !== e.target.getAttribute('data-id')) {
            this.props.handleChangeSelectedIssue(e.target.getAttribute('data-id'));
        } else {
            this.props.handleChangeSelectedIssue("");
        }
    }

    renderIssuesBlock(){
        if(this.props.issue.loading){
            return (
                <div className="text-center" style={{marginTop: '200px'}}>
                    <Spinner/>
                </div>
            );
        } else if(this.props.issue.error){
            return (
                <div className="text-center">
                    {this.props.issue.error}
                </div>
            );
        } else if(this.props.issue.issueOptions.length > 0){
            return this.renderCheckBoxes();
        }
    }

    render() {
        return (
            <motion.div key="MainModalBlockChoose"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        exit={{opacity: 0}}>
                <div className="MainModalBlockChoose">
                    <div className="title">Оберіть, лише одну ситуацію:</div>
                    <div className="issues-block">
                        {this.renderIssuesBlock()}
                    </div>
                </div>
            </motion.div>
        );
    }
}

export default MainModalBlockChoose;