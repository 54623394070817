import React from 'react';
import PhoneInput from "../utility/PhoneInput";
import {AnimatePresence, motion} from "framer-motion";

class OrderModalForm extends React.Component {
    constructor(props) {
        super(props);

        this.renderTextFormField = this.renderTextFormField.bind(this);
        this.renderPhoneFormField = this.renderPhoneFormField.bind(this);
        this.renderFormFieldError = this.renderFormFieldError.bind(this);
        this.fieldChangeHandler = this.fieldChangeHandler.bind(this);
        this.getSelectedLicenseText = this.getSelectedLicenseText.bind(this);

    }

    fieldChangeHandler(name, newVal) {
        this.props.setToStateFormField(name, newVal);
    }

    renderFormFieldError(name) {
        if (this.props.orderForm.submitErrors && this.props.orderForm.submitErrors[name]) {
            return (
                <div className="field-error">
                    {this.props.orderForm.submitErrors[name][0]}
                </div>
            );
        }
        return null;
    }

    renderTextFormField(name, label) {

        return (
            <React.Fragment>
                <div>
                    <label>{label}</label>
                    <input type={"text"}
                           className="form-control"
                           value={this.props.orderForm.formFields[name]}
                           onChange={(e) => {
                               this.fieldChangeHandler(name, e.target.value);

                           }}
                           onKeyDown={(e) => {
                               //this.shiftFocusEnter(e, name)
                           }}
                           placeholder={label}/>
                </div>
                {this.renderFormFieldError(name)}
            </React.Fragment>
        );
    }

    renderPhoneFormField(name, label) {

        return (
            <React.Fragment>
                <div>
                    <label>{label}</label>
                    <PhoneInput
                        className="form-control"
                        value={this.props.orderForm.formFields[name]}
                        onChange={(e) => {
                            this.fieldChangeHandler(name, e.target.value);

                        }}
                        onKeyDown={(e) => {
                            //this.shiftFocusEnter(e, name)
                        }}
                    />
                </div>
                {this.renderFormFieldError(name)}
            </React.Fragment>
        );
    }

    renderCheckBoxLicenseAgreementField(name) {

        return (
            <React.Fragment>
                <div className="form-check mt-4">
                    <input className="form-check-input" type="checkbox"
                           checked={this.props.orderForm.formFields[name] === "true"}
                           onChange={(e) => {
                               this.fieldChangeHandler(name, this.props.orderForm.formFields[name] === "true" ? "false" : "true");
                           }}
                           value={true}/>
                    <label className="form-check-label hover-label"
                           onClick={(e) => {
                               this.fieldChangeHandler(name, this.props.orderForm.formFields[name] === "true" ? "false" : "true");
                           }}
                    >я приймаю умови <span className="license-agreement-link" onClick={(e) => {
                        e.stopPropagation();
                        window.$("#LicenseAgreementModal").modal('show');
                    }}>Угоди користувача</span></label>
                </div>
                {this.renderFormFieldError(name)}
            </React.Fragment>
        );
    }

    getSelectedLicenseText(licenseForm) {

        const filteredLicenses = licenseForm.licenses.filter((license) => {
            return license.id === parseInt(licenseForm.selectedLicense);
        });

        if (filteredLicenses.length > 0) {

            const license = filteredLicenses[0];

            return (
                <React.Fragment>
                    <span className="license-name">{license.name}</span>
                    <span className="license-duration"> ({license.duration_days} днів)</span>
                    <span className="license-price"> за {license.price} грн</span>
                </React.Fragment>
            );
        }
        return null;
    }

    render() {
        return (
            <AnimatePresence>
                <motion.div key="jhzddxfg"
                            animate={{opacity: 1}}
                            initial={{opacity: 0}}
                            transition={{duration: 0.5}}
                            exit={{opacity: 0}}>
                    <div className="OrderModalForm">
                        <div className="row justify-content-center">
                            <div className="col-md-8">

                                <div className="selected-license-wrapper"><span
                                    className="title">Ви обрали ліцензійний ключ:</span><span
                                    className="license-text">{this.getSelectedLicenseText(this.props.licenseForm)}</span>
                                </div>

                                <div className="title">Введіть Ваші дані</div>

                                <div className="form-row">
                                    <div className="col-12 form-group">
                                        {this.renderTextFormField("name", "Ім'я")}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12 form-group">
                                        {this.renderTextFormField("email", "Email")}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12 form-group">
                                        {this.renderPhoneFormField("phone", "Номер телефону")}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-12 form-group">
                                        {this.renderCheckBoxLicenseAgreementField("license_agreement")}
                                    </div>
                                </div>

                                <div className="license-send">Після оплати ліцензійний ключ буде відправлений на
                                    вказаний Email.
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        );
    }

}

export default OrderModalForm;