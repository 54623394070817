import axios from "axios";

export const fetchContent = (language) => {
    return (dispatch) => {
        dispatch({ type: 'CONTENT_FETCH_START' });

        const url = language === 'ua' ?
            process.env.REACT_APP_API_URL_CLIENT_UA :
            process.env.REACT_APP_API_URL_CLIENT_RU;

        axios.get(url)
            .then((res) => {
                dispatch({ type: 'CONTENT_FETCH_SUCCESS', payload: res.data.data });
            })
            .catch((error) => {
                dispatch({ type: 'CONTENT_FETCH_FAIL', payload: "Произошла ошибка, попробуйте перезагрузить страницу" });
            });
    }
};

export const fetchReviews = (language) => {
    return (dispatch) => {
        dispatch({ type: 'REVIEWS_FETCH_START' });

        const url = language === 'ua' ?
            process.env.REACT_APP_API_URL_REVIEWS_UA :
            process.env.REACT_APP_API_URL_REVIEWS_RU;

        axios.get(url)
            .then((res) => {
                dispatch({ type: 'REVIEWS_FETCH_SUCCESS', payload: res.data.data });
            })
            .catch((error) => {
                dispatch({ type: 'REVIEWS_FETCH_FAIL', payload: "Ошибка получения отзывов" });
            });
    }
};

export const windowWidthSet = () => {
    return {type: 'OPTIONS_WINDOWWIDTH_SET'};
};

export const setAccessTokenToRedux = (accessToken) => {
    return {type: 'AUTH_SET_ACCESS_TOKEN', payload: accessToken};
};