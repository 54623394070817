import React from 'react';
import axios from "axios";
import getApiBaseUrlWithLang from "../utility/getApiBaseUrlWithLang";
import CustomModal from "./CustomModal";

class PrivacyPolicyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {header: null, content: null, loading: false, error: null}

        this.fetchPrivacyPolicy = this.fetchPrivacyPolicy.bind(this);

    }

    componentDidMount() {
        this.fetchPrivacyPolicy();
    }

    fetchPrivacyPolicy() {

        this.setState({loading: true});

        axios.get(`${getApiBaseUrlWithLang()}/articles/privacy-policy`)
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({
                        content: res.data.data.content,
                        header: res.data.data.header,
                        loading: false,
                        error: null
                    });
                } else if (res.data.success === false) {
                    this.setState({error: "Помилка серверу", loading: false});
                }
            })
            .catch((error) => {
                this.setState({loading: false, error: "Помилка серверу"});
            });
    }

    render() {
        return (
            <CustomModal
                modalClass="PrivacyPolicyModal"
                header={this.state.header}
                content={this.state.content}
                loading={this.state.loading}
                error={this.state.error}
            />
        );
    }
}

export default PrivacyPolicyModal;