import React from 'react';
import axios from "axios";
import getApiBaseUrlWithLang from "../utility/getApiBaseUrlWithLang";
import CustomModal from "./CustomModal";

class LicenseAgreementModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {header: null, content: null, loading: false, error: null}

        this.fetchLicenseAgreement = this.fetchLicenseAgreement.bind(this);

    }

    componentDidMount() {
        this.fetchLicenseAgreement();
    }

    fetchLicenseAgreement() {

        this.setState({loading: true});

        axios.get(`${getApiBaseUrlWithLang()}/articles/license-agreement`)
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({
                        content: res.data.data.content,
                        header: res.data.data.header,
                        loading: false,
                        error: null
                    });
                } else if (res.data.success === false) {
                    this.setState({error: "Помилка серверу", loading: false});
                }
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    }

    render() {
        return (
            <CustomModal
                modalClass="LicenseAgreementModal"
                header={this.state.header}
                content={this.state.content}
                loading={this.state.loading}
                error={this.state.error}
            />
        );
    }
}

export default LicenseAgreementModal;