import React from 'react';
import Spinner from "../utility/Spinner";

class Payment extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        document.getElementById("liqpay").click();
    }


    render() {
        return (
            <div className="Payment">
                <div className="content-wrapper">
                    <div className="title">Перехід на сторінку оплати LIQPAY...</div>
                    <Spinner customStyle={{marginTop: '50px'}} />
                </div>
                <form style={{display: 'none'}} method="POST" action="https://www.liqpay.ua/api/3/checkout"
                      acceptCharset="utf-8">
                    <input type="hidden" name="data" value={this.props.data}/>
                    <input type="hidden" name="signature" value={this.props.signature}/>
                    <button type="submit" className="btn btn-success" id="liqpay">Сплатити</button>
                </form>
            </div>
        );
    }
}

export default Payment;