import React from 'react';
import Spinner from "../utility/Spinner";

class CustomModal extends React.Component {
    constructor(props) {
        super(props);

        this.renderModalBody = this.renderModalBody.bind(this);
    }

    renderModalBody() {
        if (this.props.loading) {
            return (
                <div className="text-center">
                    <Spinner/>
                </div>
            );
        } else if (this.props.error) {
            return (
                <div className="text-center">
                    {this.props.error}
                </div>
            );
        } else if (this.props.header || this.props.content) {
            return (
                <div className="">
                    <div className="license-header">
                        {this.props.header}
                    </div>
                    <div className="license-content"
                         dangerouslySetInnerHTML={{__html: this.props.content}}/>
                    <div className="license-button-block text-center">
                        <button className="btn okButtonModal" onClick={(e) => {
                            e.preventDefault();
                            window.$(`#${this.props.modalClass}`).modal('hide');
                        }}>OK</button>
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
        return (
            <div className={this.props.modalClass}>
                <div className="modal fade" id={this.props.modalClass} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.renderModalBody()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomModal;