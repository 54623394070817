import React from 'react';
import main_modal_1 from "../images/main_modal_1.png";
import main_modal_2 from "../images/main_modal_2.png";
import issueOptions from "../res/issueOptions";
import MainModalBlockChoose from "./MainModalBlockChoose";
import MainModalBlockAuth from "./MainModalBlockAuth";
import MainModalBlockForm from "./MainModalBlockForm";
import axios from "axios";
import {connect} from "react-redux";
import {setAccessTokenToRedux} from "../actions";
import getApiBaseUrlWithLang from '../utility/getApiBaseUrlWithLang';
import moment from "moment";
import BackButton from "../utility/BackButton";
import MainModalBlockFinish from "./MainModalBlockFinish";

class MainModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            issue: {selectedIssue: "", issueOptions: [], loading: false, error: null},
            login: {codeKey: "", loading: false, error: null},
            appForm: {
                formFields: null,
                selectedIssueId: null,
                selectedIssueText: null,
                selectedIssueDescription: null,
                formWithToggler: false,
                fetchFieldsError: null,
                fetchFieldsLoading: false,
                submitLoading: false,
                submitErrors: null
            }
        };


        this.setToState = this.setToState.bind(this);
        this.setToStateFormField = this.setToStateFormField.bind(this);
        this.setToStateFormToggle = this.setToStateFormToggle.bind(this);
        this.renderModalBody = this.renderModalBody.bind(this);
        this.renderStepBlock = this.renderStepBlock.bind(this);
        this.fetchIssueOptions = this.fetchIssueOptions.bind(this);
        this.renderActiveStep = this.renderActiveStep.bind(this);
        this.getStepComponent = this.getStepComponent.bind(this);
        this.handleChangeSelectedIssue = this.handleChangeSelectedIssue.bind(this);
        this.getNextButtonStatus = this.getNextButtonStatus.bind(this);
        this.getNextButtonText = this.getNextButtonText.bind(this);
        this.getNextButtonCssClasses = this.getNextButtonCssClasses.bind(this);
        this.loginHandler = this.loginHandler.bind(this);
        this.getSubmitObj = this.getSubmitObj.bind(this);
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.renderBackButton = this.renderBackButton.bind(this);
        this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
        this.renderNextButton = this.renderNextButton.bind(this);
        this.checkForLicenseAgreementConsent = this.checkForLicenseAgreementConsent.bind(this);
        this.scrollTopErrorIntoView = this.scrollTopErrorIntoView.bind(this);
    }

    componentDidMount() {
        this.fetchIssueOptions();

        window.$(".MainModal").on('hidden.bs.modal', (e) => {
            if (this.state.step === 4) {
                this.setState((prevState) => {
                    return {
                        step: 1,
                        issue: {...prevState.issue, selectedIssue: "", loading: false, error: null},
                        appForm: {
                            formFields: null,
                            selectedIssueId: null,
                            selectedIssueText: null,
                            selectedIssueDescription: null,
                            formWithToggler: false,
                            fetchFieldsError: null,
                            fetchFieldsLoading: false,
                            submitLoading: false,
                            submitErrors: null
                        }
                    }
                });
            }
        });
    }

    renderModalBody() {
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-lg-3">
                        {this.renderStepBlock(false)}
                    </div>
                    <div className="col-lg-9">
                        {this.renderMainBlock()}
                    </div>
                </div>

            </div>
        );
    }

    renderMainBlock() {
        return this.getStepComponent();
    }

    getStepComponent() {

        switch (this.state.step) {

            case 1:
                return (
                    <MainModalBlockChoose
                        selectedIssue={this.state.issue.selectedIssue}
                        issueOptions={this.state.issue.issueOptions}
                        issue={this.state.issue}
                        handleChangeSelectedIssue={this.handleChangeSelectedIssue}
                    />);
            case 2:
                return (
                    <MainModalBlockAuth
                        setToState={this.setToState}
                        login={this.state.login}
                        handleChangeCodeKey={(e) => {
                            const val = e.target.value;
                            const formattedValue = val.replace(/ |\*/g, '');
                            this.setToState("login", {codeKey: formattedValue, error: null});
                        }}
                        onKeyDown={(e) => {
                            if(e.keyCode === 13){
                                this.loginHandler();
                            }
                        }}
                    />);
            case 3:
                return (
                    <MainModalBlockForm
                        selectedIssue={this.state.issue.selectedIssue}
                        appForm={this.state.appForm}
                        setToState={this.setToState}
                        setToStateFormField={this.setToStateFormField}
                        setToStateFormToggle={this.setToStateFormToggle}
                        optionsState={this.props.optionsState}
                    />);
            case 4:
                return (
                    <MainModalBlockFinish appForm={this.state.appForm} />);

            default:
                return (
                    <MainModalBlockChoose
                        selectedIssue={this.state.issue.selectedIssue}
                        issueOptions={this.state.issue.issueOptions}
                        handleChangeSelectedIssue={this.handleChangeSelectedIssue}
                    />);
        }
    }

    setToState(firstLevel, valueObj, changeStep, callback) {
        this.setState((prevState) => {
            return {
                ...prevState,
                step: (() => {
                    if (!changeStep) {
                        return prevState.step;
                    } else {
                        if (changeStep === "+1") {
                            return prevState.step + 1;
                        } else if (changeStep === "-1") {
                            return prevState.step - 1;
                        }
                    }
                })(),
                [firstLevel]: {
                    ...prevState[firstLevel],
                    ...valueObj
                }
            }
        }, () => {if(callback){callback()}});
    }

    setToStateFormField(name, newVal) {
        this.setState((prevState) => {

            const formFieldsUpd = prevState.appForm.formFields.map((formField) => {
                if (formField.name === name) {
                    return {...formField, val: newVal};
                }
                return formField;
            });

            let submitErrorsUpd = null;

            if (prevState.appForm.submitErrors) {
                submitErrorsUpd = {...prevState.appForm.submitErrors};
                delete submitErrorsUpd[name];
            }

            return {
                ...prevState,
                appForm: {...prevState.appForm, formFields: formFieldsUpd, submitErrors: submitErrorsUpd}
            };
        });
    }

    setToStateFormToggle(newVal, callback) {
        this.setState((prevState) => {

            const formFieldsUpd = prevState.appForm.formFields.map((formField, i) => {
                if (formField.node === 'toggler' && i === 0) {
                    return {...formField, val: newVal};
                }
                return formField;
            });

            return {
                ...prevState,
                appForm: {...prevState.appForm, formFields: formFieldsUpd}
            };
        }, callback ? callback : null);
    }

    fetchIssueOptions() {
        //this.setToState("issue", {issueOptions: issueOptions.data});

        this.setToState("issue", {loading: true, error: null});

        axios.get(`${getApiBaseUrlWithLang()}/application-forms`)
            .then((res) => {
                if (res.data.success === true) {
                    this.setToState("issue",
                        {issueOptions: res.data.data, loading: false, error: null}
                    );
                } else if (res.data.success === false) {
                    this.setToState("issue",
                        {issueOptions: [], loading: false, error: "Помилка серверу"}
                    );
                }
            })
            .catch((error) => {
                this.setToState("issue",
                    {issueOptions: [], loading: false, error: "Помилка серверу"}
                );
            });
    }

    handleChangeSelectedIssue(value) {
        this.setToState("issue", {selectedIssue: value});
    }


    renderActiveStep(step) {
        return this.state.step >= step ? 'active' : '';
    }

    getNextButtonStatus() {
        if (this.state.step === 1) {
            return this.state.issue.selectedIssue !== "";
        } else if (this.state.step === 2) {
            return this.state.login.codeKey.length > 0;
        } else if (this.state.step === 3) {
            if (!this.state.appForm.formFields) {
                return false;
            }
            if(this.state.appForm.submitLoading){
                return false;
            }
            if (this.state.appForm.formWithToggler
                && this.state.appForm.formFields
                && this.state.appForm.formFields[0].val === 'false') {
                return false;
            }
            return true;
        }
        return false;
    }

    getNextButtonText() {

        if (this.state.step === 2) {
            if (this.state.login.loading) {
                return (<i className='fa fa-spinner fa-pulse fa-1x fa-fw'></i>);
            }
        } else if (this.state.step === 3) {
            if (this.state.appForm.submitLoading) {
                return (<i className='fa fa-spinner fa-pulse fa-1x fa-fw'></i>);
            }
            return "Отримати лист";
        }
        return "Далі";
    }

    getNextButtonCssClasses() {
        if (this.state.step === 3) {
            return "btn customButtonNext step3";
        }
        return "btn customButtonNext";
    }

    nextButtonClickHandler() {
        if (this.state.step === 1) {
            if (this.props.authState.accessToken !== null) {
                this.setState({step: 3});
            } else {
                this.setState({step: 2});
            }
        } else if (this.state.step === 2) {
            this.loginHandler();
        } else if (this.state.step === 3) {
            this.submitFormHandler();
        }
    }

    getSubmitObj() {
        const formFields = this.state.appForm.formFields;

        let obj = {};

        for (let i = 0; i < formFields.length; i++) {
            if (formFields[i].node === 'field') {
                if (formFields[i].type === 'date' && formFields[i].val !== "") {
                    obj[formFields[i]['name']] = moment(formFields[i].val).format('DD.MM.YYYY');
                } else if(formFields[i].name === 'phone' || formFields[i].name === 'post_index'){
                    const formattedValue = formFields[i].val.replace(/ |_/g, '');
                    obj[formFields[i]['name']] = formattedValue;
                } else {
                    obj[formFields[i]['name']] = formFields[i].val;
                }
            }
        }
        return obj;
    }

    loginHandler() {

        this.setToState("login", {loading: true, error: null});

        axios.post(`${getApiBaseUrlWithLang()}/login`, {login: this.state.login.codeKey})
            .then((res) => {
                if (res.data.success === true) {
                    this.props.setAccessTokenToRedux(res.data.data.access_token);
                    this.setToState("login", {loading: false, error: null, codeKey: ""}, "+1");

                } else if (res.data.success === false && res.data.data.errors && res.data.data.errors.login) {
                    this.setToState("login", {error: res.data.data.errors.login[0], loading: false});
                }
            })
            .catch((error) => {
                this.setToState("login", {error: "Ошибка сервера", loading: false});
            });
    }

    submitFormHandler() {

        if(!this.checkForLicenseAgreementConsent()) return;

        this.setToState("appForm", {submitLoading: true, submitError: null});

        axios.post(`${getApiBaseUrlWithLang()}/application-forms/${this.state.issue.selectedIssue}/applications`,
            {fields: this.getSubmitObj()},
            {headers: {'Authorization': `Basic ${btoa(this.props.authState.accessToken + ':')}`}}
        )
            .then((res) => {
                if (res.data.success === true) {
                    //this.setToState("login", {loading: false, error: null, codeKey: ""}, "+1");
                    this.setToState("appForm", {submitLoading: false}, "+1") ;

                } else if (res.data.success === false && res.data.data.errors) {
                    this.setToState(
                        "appForm",
                        {submitErrors: res.data.data.errors, submitLoading: false},
                        false,
                        () => {this.scrollTopErrorIntoView()}
                        );
                }
            })
            .catch((error) => {
                //this.setToState("login", {error: "Ошибка сервера", loading: false});
                this.setToState("appForm", {submitLoading: false});
            });
    }

    checkForLicenseAgreementConsent(){
        const formFields = this.state.appForm.formFields;

        if(formFields[formFields.length -1].name === 'license_agreement'
            && formFields[formFields.length -1].val === 'true'){
            return true;
        }

        const errorText = this.props.optionsState.language === "ua" ?
            "Вам потрібно прийняти умови угоди" :
            "Вам необходимо принять условия соглашения";

        this.setToState(
            'appForm',
            {submitErrors: {license_agreement: [errorText]}},
            false,
            () => {this.scrollTopErrorIntoView()});

        return false;
    }


    renderStepBlock(mobile) {
        return (
            <div className={`StepBlock ${mobile ? 'd-lg-none' : 'd-none d-lg-block'}`}>
                <div className={`image-wrapper ${mobile ? 'd-none' : ''}`}>
                    <img src={main_modal_1} alt=""/>
                </div>
                <div className={`step-block-wrapper step${this.state.step}`}>
                    <div className={`step-item ${this.renderActiveStep(1)}`}>
                        Оберіть, будь ласка, <br/>Вашу ситуацію
                    </div>
                    <div className={`step-line ${this.renderActiveStep(2)}`}/>

                    <div className={`step-item ${this.renderActiveStep(2)}`}>
                        Вхід до системи
                    </div>
                    <div className={`step-line ${this.renderActiveStep(3)}`}/>

                    <div className={`step-item ${this.renderActiveStep(3)}`}>
                        Заповнення анкети
                    </div>
                    <div className={`step-line ${this.renderActiveStep(4)}`}/>

                    <div className={`step-item ${this.renderActiveStep(4)}`}>
                        Завершення
                    </div>
                </div>
            </div>
        );
    }

    renderBackButton() {
        if (this.state.step === 2 || this.state.step === 3) {
            return (<BackButton handleBackButtonClick={this.handleBackButtonClick}/>);
        }
        return null
    }

    handleBackButtonClick() {
        this.setState({step: 1});
    }

    renderNextButton() {
        if (this.state.step < 4) {
            return (
                <button className={this.getNextButtonCssClasses()}
                        onClick={() => {
                            this.nextButtonClickHandler()
                        }}
                        disabled={!this.getNextButtonStatus()}
                >
                    {this.getNextButtonText()}
                </button>);
        }
        return null;
    }

    scrollTopErrorIntoView(){

        if(this.state.appForm.submitErrors){

            const elName = Object.keys(this.state.appForm.submitErrors)[0];

            if(document.querySelector(`[data-name=${elName}]`)){
                document.querySelector(`[data-name=${elName}]`)
                    .scrollIntoView({behavior: "smooth"});
            }
        }
    }

    render() {
        return (
            <div className="MainModal">
                <div className="container">
                    <div className="modal fade" id="MainModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                             role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {this.renderBackButton()}
                                    <h5 className="modal-title"></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {this.renderStepBlock(true)}
                                {this.renderModalBody()}
                                <div className="button-block">
                                    {this.renderNextButton()}
                                </div>
                                {/*<img className="img-right" src={main_modal_2} alt=""/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(null, {setAccessTokenToRedux})(MainModal);