import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale} from "react-datepicker";
import uk from 'date-fns/locale/uk';
registerLocale('uk', uk);

function DatePickerCustom(props){
    return (
        <DatePicker
            selected={props.date}
            onChange={props.handleDateChange}
            popperPlacement="top-start"
            dateFormat="dd.MM.yyyy"
            locale="uk"
            minDate={props.minDate}
            maxDate={props.maxDate}
            placeholderText={props.placeholderText}
            withPortal={props.withPortal}
            className={`customCalendar ${props.fieldName}`}
        />
    );
}

export default DatePickerCustom;