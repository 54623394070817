export default (state = {
    accessToken: null,
    user: null,
    error: null,
    loading: false,
    localChecked: false
}, action) => {

    switch (action.type) {

        // case 'AUTH_ERASE_ERROR':
        //     return {...state, error: null};
        //
        // case 'AUTH_START':
        //     return {...state, error: null, loading: true};
        //
        // case 'AUTH_AUTO_SUCCESS':
        //     return {...state, token: action.token, user: action.user, localChecked: true};
        //
        // case 'AUTH_AUTO_FAIL':
        //     return {...state, localChecked: true };
        //
        // case 'AUTH_SUCCESS':
        //     return {...state, token: action.payload.token, user: action.payload.user, loading: false};
        //
        // case 'AUTH_FAIL':
        //     return {...state, token: null, error: action.error, loading: false};
        //
        // case 'AUTH_LOGOUT':
        //     return {...state, token: null, name: null, error: null, loading: false, localChecked: true};

        case 'AUTH_SET_ACCESS_TOKEN':
            return {...state, accessToken: action.payload};


        default:
            return state;

    }
}