import React from 'react';
import stepmap from "../images/stepmap.svg";
import stepmap_mobile from "../images/stepmap_mobile.svg";

import step_map_icon_1 from "../images/step_map_icon_1.png";
import step_map_icon_2 from "../images/step_map_icon_2.png";
import step_map_icon_3 from "../images/step_map_icon_3.png";
import step_map_icon_4 from "../images/step_map_icon_4.png";
import step_map_icon_5 from "../images/step_map_icon_5.png";

import step_map_person_1 from "../images/step_map_person_1.png";
import step_map_person_2 from "../images/step_map_person_2.png";
import step_map_person_3 from "../images/step_map_person_3.png";

class StepMap extends React.Component {
    constructor(props) {
        super(props);

        this.getStepMapImage = this.getStepMapImage.bind(this);
        this.renderMobile = this.renderMobile.bind(this);
        this.renderIconBlocks = this.renderIconBlocks.bind(this);

        this.state = {
            stepTexts: [
                "Заповнюєте анкету",
                "Отримайте готовий лист",
                "Передача листа продавцеві",
                "Виконання законних вимог",
                "Покарання в разі відмови"
            ]
        }
    }

    getStepMapImage() {
        const currentWidth = this.props.optionsState.windowWidth;

        if (currentWidth < 768) {
            return stepmap_mobile;
        } else {
            return stepmap;
        }
    }

    renderImage(i) {

        switch (i) {
            case 0:
                return step_map_icon_1;
            case 1:
                return step_map_icon_2;
            case 2:
                return step_map_icon_3;
            case 3:
                return step_map_icon_4;
            case 4:
                return step_map_icon_5;
            default:
                return step_map_icon_1;
        }
    }

    renderIconBlocks() {
        return this.state.stepTexts.map((stepText, i) => {

            let lineBlock = null;

            if (i > 0) {
                lineBlock = (
                    <div className="line-wrapper">
                        <div className={`line line${i + 1}`}></div>
                    </div>
                );
            }

            let iconWrapper = (
                <div className="icon-wrapper">
                    <div className={`icon icon${i + 1}`}>
                        <img src={this.renderImage(i)} alt=""/>
                    </div>
                </div>
            );

            if (i === 2) {
                iconWrapper = (
                    <div className="icon-wrapper">
                        <div className={`icon icon${i + 1}`}>
                            <img src={this.renderImage(i)} alt=""/>
                            <img className="step-map-person2" src={step_map_person_2} alt=""/>
                        </div>
                    </div>
                );
            } else if (i === 4) {
                iconWrapper = (
                    <div className="icon-wrapper">
                        <div className={`icon icon${i + 1}`}>
                            <img src={this.renderImage(i)} alt=""/>
                            <img className="step-map-person3" src={step_map_person_3} alt=""/>
                        </div>
                    </div>
                );
            }

            return (
                <div className="item-wrapper" key={i}>
                    {lineBlock}
                    {iconWrapper}
                    <div className="text-wrapper">
                        <div className="text">
                            {stepText}
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderIconBlocksDesktop() {
        return this.state.stepTexts.map((stepText, i) => {

            let lineBlock = null;

            if (i > 0) {
                lineBlock = (
                    <div className={`line-wrapper item${i + 1}`}>
                        <div className={`line line${i + 1}`}></div>
                    </div>
                );
            }

            let iconWrapper = (
                <div className="icon-wrapper">
                    <div className={`icon icon${i + 1}`}>
                        <img src={this.renderImage(i)} alt=""/>
                    </div>
                </div>
            );

            if (i === 0) {
                iconWrapper = (
                    <div className="icon-wrapper">
                        <div className={`icon icon${i + 1}`}>
                            <img src={this.renderImage(i)} alt=""/>
                            <img className="step-map-person1" src={step_map_person_1} alt=""/>
                        </div>
                    </div>
                );
            } else if (i === 1) {
                iconWrapper = (
                    <div className="icon-wrapper">
                        <div className={`icon icon${i + 1}`}>
                            <img src={this.renderImage(i)} alt=""/>
                            <img className="step-map-person2" src={step_map_person_2} alt=""/>
                        </div>
                    </div>
                );
            } else if (i === 4) {
                iconWrapper = (
                    <div className="icon-wrapper">
                        <div className={`icon icon${i + 1}`}>
                            <img src={this.renderImage(i)} alt=""/>
                            <img className="step-map-person3" src={step_map_person_3} alt=""/>
                        </div>
                    </div>
                );
            }

            return (
                <React.Fragment key={i}>
                    <div className={`item-wrapper item${i + 1}`} key={i}>
                        {iconWrapper}
                        <div className="text-wrapper">
                            <div className="text">
                                {stepText}
                            </div>
                        </div>
                    </div>
                    {lineBlock}
                </React.Fragment>
            );
        });
    }

    renderMobile() {

        return (
            <div className="StepMapMobile d-lg-none">
                <div className="container content-wrapper">
                    <div className="title">Як працювати з юридичним онлайн-сервісом?</div>
                    {this.renderIconBlocks()}
                </div>
                <div className="button-block">
                    <button className="btn custom-button" data-toggle="modal" data-target="#MainModal">Заповнити
                        анкету
                    </button>
                </div>
                <img className="step-map-person1" src={step_map_person_1} alt=""/>
            </div>);
    }

    renderDesktop() {
        return (
            <div className="StepMap d-none d-lg-block">
                <div className="container content-wrapper">
                    <div className="title">Як працювати з юридичним онлайн-сервісом?</div>
                    {this.renderIconBlocksDesktop()}
                </div>
                <div className="button-block">
                    <button className="btn custom-button" data-toggle="modal" data-target="#MainModal">Заповнити
                        анкету
                    </button>
                </div>
            </div>);
    }

    render() {
        return (
            <React.Fragment>
                {this.renderDesktop()}
                {this.renderMobile()}
            </React.Fragment>
        );
    }

}

export default StepMap;