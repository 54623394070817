import React from 'react';
import axios from "axios";
import getApiBaseUrlWithLang from "../utility/getApiBaseUrlWithLang";
import Spinner from "../utility/Spinner";
import feedback_success from "../images/feedback_success.png";
import {AnimatePresence, motion} from "framer-motion";

class FeedBackModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            name: "",
            email: "",
            text: "",
            fetchIdentityChecked: false,
            fetchFieldsError: null,
            fetchFieldsLoading: false,
            submitLoading: false,
            submitErrors: null,
            submitFormSuccess: false
        };

        this.formFieldPlaceholders = {
            name: "Введіть ім'я",
            email: "Введіть пошту",
            text: "Напишіть запитання/відгук"

        };

        this.fetchIdentity = this.fetchIdentity.bind(this);
        this.submitFeedBackHandler = this.submitFeedBackHandler.bind(this);
        this.fieldChangeHandler = this.fieldChangeHandler.bind(this);
        this.renderFormFieldError = this.renderFormFieldError.bind(this);
        this.getButtonText = this.getButtonText.bind(this);
        this.renderForm = this.renderForm.bind(this);

    }

    componentDidMount() {
        if (this.props.authState.accessToken) {
            this.fetchIdentity();
        }

        window.$("#FeedBackModal").on('show.bs.modal', (e) => {
            if (this.props.authState.accessToken
                && !this.state.fetchIdentityChecked
            ) {
                this.fetchIdentity();
            }
        });

        window.$("#FeedBackModal").on('hidden.bs.modal', (e) => {
            if (this.state.submitFormSuccess) {
                this.setState({
                    name: "",
                    email: "",
                    text: "",
                    fetchIdentityChecked: false,
                    fetchFieldsError: null,
                    fetchFieldsLoading: false,
                    submitLoading: false,
                    submitErrors: null,
                    submitFormSuccess: null
                });
            }
        });

    }

    fieldChangeHandler(name, newVal) {
        this.setState((prevState) => {
            return {
                ...prevState,
                [name]: newVal
            };
        });
    }

    renderFormFieldError(name) {
        if (this.state.submitErrors && this.state.submitErrors[name]) {
            return (
                <div className="field-error">
                    {this.state.submitErrors[name][0]}
                </div>
            );
        }
        return null;
    }

    fetchIdentity() {

        this.setState({loading: true});

        axios.get(`${getApiBaseUrlWithLang()}/identity`,
            {headers: {'Authorization': `Basic ${btoa(this.props.authState.accessToken + ':')}`}})
            .then((res) => {
                if (res.data.success === true) {
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            loading: false,
                            error: null,
                            fetchIdentityChecked: true,
                            name: res.data.data.name ? res.data.data.name : "",
                            email: res.data.data.email ? res.data.data.email : ""
                        };
                    });
                } else if (res.data.success === false) {
                    this.setState({error: "Помилка серверу", loading: false, fetchIdentityChecked: true});
                }
            })
            .catch((error) => {
                this.setState({loading: false, fetchIdentityChecked: true});
            });
    }

    submitFeedBackHandler() {

        this.setState({submitLoading: true, submitError: null});

        axios.post(`${getApiBaseUrlWithLang()}/feedbacks`,
            {name: this.state.name, email: this.state.email, text: this.state.text})
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({submitLoading: false, submitFormSuccess: res.data.data.message});
                } else if (res.data.success === false && res.data.data.errors) {
                    this.setState({submitErrors: res.data.data.errors, submitLoading: false});
                }
            })
            .catch((error) => {
                this.setState({submitLoading: false});
            });
    }

    getButtonText() {

        if (this.state.submitLoading) {
            return (<i className='fa fa-spinner fa-pulse fa-1x fa-fw'></i>);
        }
        return "Відправити";
    }

    renderForm() {
        return (
            <div className="">
                <div className="feedback-header">
                    Введіть, будь ласка, Ваші контактні дані:
                </div>
                <div className="feedback-form">

                    <div className="container p-0">
                        <div className="row my-4">
                            <div className="col-12">
                                <div>
                                    <input type="text"
                                           className="form-control"
                                           value={this.state.name}
                                           onChange={(e) => {
                                               this.fieldChangeHandler('name', e.target.value);

                                           }}
                                           placeholder={this.formFieldPlaceholders.name}/>
                                </div>
                                {this.renderFormFieldError('name')}
                            </div>
                        </div>

                        <div className="row my-4">
                            <div className="col-12">
                                <div>
                                    <input type="text"
                                           className="form-control"
                                           value={this.state.email}
                                           onChange={(e) => {
                                               this.fieldChangeHandler('email', e.target.value);

                                           }}
                                           placeholder={this.formFieldPlaceholders.email}/>
                                </div>
                                {this.renderFormFieldError('email')}
                            </div>
                        </div>

                        <div className="row my-4">
                            <div className="col-12">
                                <div>
                                        <textarea
                                            rows={5}
                                            className="form-control"
                                            value={this.state.text}
                                            onChange={(e) => {
                                                this.fieldChangeHandler('text', e.target.value)
                                            }}
                                            placeholder={this.formFieldPlaceholders.text}/>
                                </div>
                                {this.renderFormFieldError('text')}
                            </div>
                        </div>

                        <div className="button-wrapper">
                            <button className="btn okButtonModal" disabled={this.state.submitLoading} onClick={(e) => {
                                e.preventDefault();
                                this.submitFeedBackHandler();
                            }}>{this.getButtonText()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSuccessBlock() {
        return (
            <AnimatePresence>
                <motion.div key="jzdjhg"
                            animate={{opacity: 1}}
                            initial={{opacity: 0}}
                            transition={{duration: 0.5}}
                            exit={{opacity: 0}}>
                    <div className="FeedBackSuccess">
                        <img className="success-image" src={feedback_success} alt=""/>
                        <div className="text">Ми розглянемо Ваш лист найближчим часом і зв'яжемося з Вами</div>
                        <div className="thanks">Дякуємо за звернення!</div>
                    </div>
                </motion.div>
            </AnimatePresence>
        );
    }


    renderModalBody() {
        if (this.props.loading) {
            return (
                <div className="text-center">
                    <Spinner/>
                </div>
            );
        } else if (this.state.submitFormSuccess) {
            return this.renderSuccessBlock();
        } else {
            return this.renderForm();
        }
    }

    render() {
        return (
            <div className="FeedBackModal">
                <div className="modal fade" id="FeedBackModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.renderModalBody()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeedBackModal;