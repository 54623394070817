import React from 'react';

class LetterToUs extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="container-md p-0">
                <div className="LetterToUs">
                    <div className="overlay"></div>
                    <div className="content-wrapper">
                        <div className="title1">
                            У Вас залишилися питання?
                        </div>
                        <div className="title2">
                            У Вас вже є результат?
                        </div>

                        <div className="button-wrapper">
                            <button className="btn custom-button" data-toggle="modal" data-target="#FeedBackModal">
                                Написати в ЮОС
                            </button>
                        </div>

                        <div className="text-block">
                            ЮОС очікує 24/7/365
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default LetterToUs;