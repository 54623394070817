export default (state = {
    content: null,
    loading: false,
    error: null
}, action) => {

    switch (action.type) {

        case 'CONTENT_FETCH_START':
            return { ...state, loading: true, error: null };
        case 'CONTENT_FETCH_SUCCESS':
            return { ...state, content: action.payload, loading: false, error: null };
        case 'CONTENT_FETCH_FAIL':
            return { ...state, content: null, loading: false, error: action.payload};

        default:
            return state;

    }
}