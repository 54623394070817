import React from 'react';
import {AnimatePresence, motion} from "framer-motion";
import LicenseKeyInput from "../utility/LicenseKeyInput";

class MainModalBlockAuth extends React.Component {

    constructor(props) {
        super(props);

        this.renderErrorBlock = this.renderErrorBlock.bind(this);

    }

    componentWillUnmount() {
        this.props.setToState("login", {codeKey: "", loading: false, error: null});
    }

    renderErrorBlock() {
        if (this.props.login.error !== null) {
            return (
                <div className="text-error">
                    {this.props.login.error}
                </div>
            );
        }
        return null;
    }

    render() {

        return (
            <AnimatePresence>
                <motion.div key="jzd"
                            animate={{opacity: 1}}
                            initial={{opacity: 0}}
                            transition={{duration: 0.5}}
                            exit={{opacity: 0}}>
                    <div className="MainModalBlockAuth">
                        <div className="title">Введіть код</div>
                        <LicenseKeyInput
                            name="promoCode"
                            className="form-control"
                            value={this.props.codeKey}
                            onChange={this.props.handleChangeCodeKey}
                            onKeyDown={this.props.onKeyDown}
                        />
                        {this.renderErrorBlock()}

                        {/*<div className="buy-license-wrapper">*/}
                            {/*<div className="buy-license-question">*/}
                                {/*У вас немає коду або закінчися термін дії?*/}
                            {/*</div>*/}
                            {/*<div className="buy-license">*/}
                                {/*<span data-toggle="modal" data-target="#OrderModal">Купити код</span>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        <div className="content">
                            <div className="item">Для користування юридичним онлайн-сервісом необхідно придбати
                                ліцензійний ключ (код).
                            </div>
                            <div className="item">Код можна придбати в мережах партнерів та безпосередньо у
                                правовласника <span className="buy-license" data-toggle="modal" data-target="#OrderModal">за посиланням</span>.
                            </div>
                            <div className="item">З питань придбання ліцензійних ключів та співпраці звертайтесь
                                будь-ласка на e-mail: <span className="text-email">votu@votu.com.ua</span></div>
                            <div className="item">З повагою, команда професійних юристів ВОТУ!</div>
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        );
    }
}

export default MainModalBlockAuth;