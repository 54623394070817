import React from 'react';
import finish_image from '../images/finish.png';
import {motion} from "framer-motion";

class MainModalBlockFinish extends React.Component {
    constructor(props) {
        super(props);

        this.renderEmailFromForm = this.renderEmailFromForm.bind(this);
    }

    renderEmailFromForm() {
        if (this.props.appForm.formFields) {

            const filteredArray = this.props.appForm.formFields.filter((formField) => {
                return formField.name === 'email';
            });

            if (filteredArray.length > 0 && filteredArray[0].val !== '') {
                return (<span className="text-email">{filteredArray[0].val}</span>);
            }
        }

        return '';
    }

    render() {
        return (
            <motion.div key="MainModalBlockFinish"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        exit={{opacity: 0}}>
                <div className="MainModalBlockFinish">
                    <img className="success-image" src={finish_image} alt=""/>
                    <div className="title">Готово!</div>
                    <div className="text">Ваша Заява успішно створена і вже відправлена Вам на електронну
                        пошту{this.renderEmailFromForm()}.
                    </div>
                    <div className="text-second">Повідомте нам, будь ласка, про результати вирішення Вашого питання на
                        електронну пошту <span className="text-email">votu@votu.com.ua</span>.
                    </div>
                    <div className="thanks">Дякуємо за звернення!</div>
                </div>
            </motion.div>
        );
    }

}

export default MainModalBlockFinish;