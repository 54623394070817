import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import OptionsReducer from './OptionsReducer';
import ContentReducer from './ContentReducer';
import ReviewsReducer from './ReviewsReducer';

const appReducer = combineReducers(
    {
        authState: AuthReducer,
        optionsState: OptionsReducer,
        contentState: ContentReducer,
        reviewsState: ReviewsReducer
    }
);

export default appReducer;