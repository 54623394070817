import React from 'react';
import InputMask from 'react-input-mask';

class LicenseKeyInput extends React.Component {
    render() {
        return <InputMask
            {...this.props}
            mask="* * * * * * * *"
            maskChar="*"
            alwaysShowMask={true}
            formatChars={{
                '9': '[0-9]',
                'a': '[A-Za-z]',
                '*': '[A-Za-zА-Яа-я0-9]'
            }}
        />;
    }
}

export default LicenseKeyInput;