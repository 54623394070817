import React from 'react';

class VideoModal extends React.Component {
    constructor(props) {
        super(props);


        this.getVideoSrc = this.getVideoSrc.bind(this);
    }

    componentDidMount() {
        window.$("#VideoModal").on("hidden.bs.modal", this.props.changeShowVideo);
    }

    getVideoSrc() {
        const currentLanguage = this.props.optionsState.language;

        return currentLanguage === 'ua' ?
            process.env.REACT_APP_VIDEO_SRC_UA :
            process.env.REACT_APP_VIDEO_SRC_RU;
    }

    renderModalBody(){

        if(!this.props.show) return null;

        return (
            <iframe style={{width: '100%', height: '550px'}} src={this.getVideoSrc()} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        );
    }

    render() {

        return (
            <div className="VideoModal">
                <div className="container">
                    <div className="modal fade" id="VideoModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                             role="document">
                            <div className="modal-content videoModalContent">
                                <div className="modal-header">
                                    <h5 className="modal-title">Робота сервісу votu.com.ua</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.renderModalBody()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default VideoModal;