import React from 'react';
import {motion} from "framer-motion";
import formFields from "../res/formFields";
import Spinner from "../utility/Spinner";
import DatePickerCustom from "../utility/DatePickerCustom";
import axios from "axios";
import getApiBaseUrlWithLang from "../utility/getApiBaseUrlWithLang";
import PhoneInput from "../utility/PhoneInput";
import ZipInput from "../utility/ZipInput";

class MainModalBlockForm extends React.Component {
    constructor(props) {
        super(props);

        this.fetchFormFields = this.fetchFormFields.bind(this);
        this.renderTextFormField = this.renderTextFormField.bind(this);
        this.renderTextAreaFormField = this.renderTextAreaFormField.bind(this);
        this.renderSelectFormField = this.renderSelectFormField.bind(this);
        this.renderDateFormField = this.renderDateFormField.bind(this);
        this.getFilteredArr = this.getFilteredArr.bind(this);
        this.getFieldObj = this.getFieldObj.bind(this);
        this.renderFormFieldError = this.renderFormFieldError.bind(this);
        this.renderCheckBoxFormField = this.renderCheckBoxFormField.bind(this);
        this.renderSellerBlockFields = this.renderSellerBlockFields.bind(this);
        this.renderDisplayOfDependant = this.renderDisplayOfDependant.bind(this);
        this.renderToggleBlock = this.renderToggleBlock.bind(this);
        this.renderFormWithToggler = this.renderFormWithToggler.bind(this)
        this.renderDescription = this.renderDescription.bind(this);
        this.togglerChangeHandler = this.togglerChangeHandler.bind(this);
        this.renderPhoneFormField = this.renderPhoneFormField.bind(this);
        this.renderZipFormField = this.renderZipFormField.bind(this);
    }

    componentDidMount() {

        if (parseInt(this.props.selectedIssue) !== this.props.appForm.selectedIssueId) {
            this.fetchFormFields();
        }

        window.setTimeout(function () {
            document.querySelectorAll(".customCalendar").forEach(function (el) {
                el.setAttribute("readonly", "readonly");
            });
        }, 800);

    }

    transformFormFields(formFields) {

        const dependencyObj = {};

        let formWithToggler = false;

        const arr = formFields.map((formField, i) => {
            if (formField.node === "field") {
                if (formField.type === "checkbox") {
                    if (formField.rules.depends) {
                        formField.rules.depends.forEach((dependant) => {
                            dependencyObj[dependant] = formField.name;
                        });
                    }
                    return {...formField, val: "false"};
                }
                return {...formField, val: ""};
            } else if (formField.node === "toggler" && i === 0) {
                formWithToggler = true;
                return {...formField, val: "false"};
            }
            return formField;
        }).map((formField, i) => {
            if (i === 0) {
                this.props.setToState("appForm", {formWithToggler: formWithToggler});
            }
            if (formField.node === "field" && dependencyObj[formField.name]) {
                return {...formField, dependency: dependencyObj[formField.name]};
            }
            return formField;
        });

        arr.push({
            node: "field",
            type: "checkbox",
            name: "license_agreement",
            label: "я приймаю умови Угоди користувача",
            width: 100,
            val: "false"
        });

        return arr;
    }

    fetchFormFields() {

        this.props.setToState("appForm", {
            fetchFieldsLoading: true,
            fetchFieldsError: null,
            submitErrors: null,
            submitLoading: false
        });

        axios.get(`${getApiBaseUrlWithLang()}/application-forms/${this.props.selectedIssue}?expand=form`)
            .then((res) => {
                if (res.data.success === true) {
                    this.props.setToState("appForm",
                        {
                            formFields: this.transformFormFields(res.data.data.form),
                            selectedIssueId: res.data.data.id,
                            selectedIssueText: res.data.data.title,
                            selectedIssueDescription: res.data.data.description,
                            fetchFieldsLoading: false,
                            fetchFieldsError: null
                        }
                    );
                } else if (res.data.success === false) {
                    this.props.setToState("appForm",
                        {fetchFieldsLoading: false, fetchFieldsError: "Ошибка сервера"}
                    );
                }
            })
            .catch((error) => {
                this.props.setToState("appForm",
                    {fetchFieldsLoading: false, fetchFieldsError: "Ошибка сервера"}
                );
            });
    }

    getFilteredArr(name) {
        return this.props.appForm.formFields.filter(function (formField) {
            return formField.name === name;
        });
    }

    getFieldObj(name) {
        return this.getFilteredArr(name)[0];
    }

    fieldChangeHandler(name, newVal) {
        this.props.setToStateFormField(name, newVal);
    }

    renderFormFieldError(name) {
        if (this.props.appForm.submitErrors && this.props.appForm.submitErrors[name]) {
            return (
                <div className="field-error">
                    {this.props.appForm.submitErrors[name][0]}
                </div>
            );
        }
        return null;
    }

    renderDisplayOfDependant(dependency) {
        const dependencyObj = this.getFieldObj(dependency);

        if (dependencyObj.val === 'false') {
            return 'd-none';
        }
        return '';
    }

    shiftFocusEnter(e, name) {

        if (e.keyCode === 13) {

            this.shiftFocus(name);
        }
    }

    shiftFocus(name) {

        let currentIndex = 0;

        for (let i = 0; i < this.props.appForm.formFields.length; i++) {
            if (this.props.appForm.formFields[i].name === name) {
                currentIndex = i;
                break;
            }
        }

        let nextName = this.props.appForm.formFields[currentIndex + 1].name;

        if (nextName) {
            let nextEl = document.querySelector(`[data-name="${nextName}"]`) ||
                document.querySelector(`.customCalendar.${nextName}`);

            if (nextEl) {

                nextEl.focus();

                const labelEl = nextEl.previousSibling;

                if (labelEl) {
                    labelEl.scrollIntoView({behavior: "smooth"});
                }
            }
        }
    }

    renderTextFormField(name, number) {

        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            return (
                <React.Fragment>
                    <div>
                        <label>{formObj.label}</label>
                        <input type={number ? "number" : "text"}
                               className="form-control"
                               data-name={name}
                               value={this.getFieldObj(name).val}
                               onChange={(e) => {
                                   this.fieldChangeHandler(name, e.target.value);

                               }}
                               onKeyDown={(e) => {
                                   this.shiftFocusEnter(e, name)
                               }}
                               placeholder={formObj.description}/>
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );
        }
        return null;
    }

    renderPhoneFormField(name) {

        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            return (
                <React.Fragment>
                    <div>
                        <label>{formObj.label}</label>
                        <PhoneInput
                               className="form-control"
                               data-name={name}
                               value={this.getFieldObj(name).val}
                               onChange={(e) => {
                                   this.fieldChangeHandler(name, e.target.value);

                               }}
                               onKeyDown={(e) => {
                                   this.shiftFocusEnter(e, name)
                               }}
                               />
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );
        }
        return null;
    }

    renderZipFormField(name) {

        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            return (
                <React.Fragment>
                    <div>
                        <label>{formObj.label}</label>
                        <ZipInput
                            className="form-control"
                            data-name={name}
                            value={this.getFieldObj(name).val}
                            onChange={(e) => {
                                this.fieldChangeHandler(name, e.target.value);

                            }}
                            onKeyDown={(e) => {
                                this.shiftFocusEnter(e, name)
                            }}
                        />
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );
        }
        return null;
    }

    renderTextAreaFormField(name) {

        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            return (
                <React.Fragment>
                    <div>
                        <label>{formObj.label}</label>
                        <textarea
                            rows={2}
                            className="form-control"
                            data-name={name}
                            value={this.getFieldObj(name).val}
                            onChange={(e) => {
                                this.fieldChangeHandler(name, e.target.value)
                            }}
                            placeholder={formObj.description}
                            onKeyDown={(e) => {
                                this.shiftFocusEnter(e, name)
                            }}
                        />
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );
        }
        return null;
    }

    renderSelectFormField(name) {
        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            const selectOptions = Object.keys(formObj.values).map(function (optionKey, i) {
                if (i === 0) {
                    return (
                        <React.Fragment key="0">
                            <option key="0" value="" disabled={true}>
                                {formObj.description}
                            </option>
                            <option key={optionKey} value={optionKey}>
                                {formObj.values[optionKey]}
                            </option>
                        </React.Fragment>);
                }
                return (
                    <option key={optionKey} value={optionKey}>
                        {formObj.values[optionKey]}
                    </option>);

            });
            return (

                <React.Fragment>
                    <div>
                        <label>{formObj.label}</label>
                        <select className="form-control"
                                data-name={name}
                                value={this.getFieldObj(name).val}
                                onChange={(e) => {
                                    this.fieldChangeHandler(name, e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    this.shiftFocusEnter(e, name)
                                }}
                        >
                            {selectOptions}
                        </select>
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );

        }
        return null;
    }

    renderDateFormField(name) {

        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            return (
                <React.Fragment>
                    <div>
                        <label>{formObj.label}</label>
                        <DatePickerCustom
                            date={this.getFieldObj(name).val}
                            maxDate={new Date()}
                            handleDateChange={(date) => {
                                this.fieldChangeHandler(name, date);
                            }}
                            placeholderText={formObj.description}
                            withPortal={this.props.optionsState.windowWidth < 768}
                            fieldName={name}
                        />
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );
        }
        return null;
    }

    renderCheckBoxFormField(name) {
        if (name === 'license_agreement') {
            return this.renderCheckBoxLicenseAgreementField(name);
        }

        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            return (
                <React.Fragment>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                               checked={formObj.val === "true"}
                               data-name={name}
                               onChange={(e) => {
                                   this.fieldChangeHandler(name, formObj.val === "true" ? "false" : "true");
                               }}
                               value={true}/>
                        <label className="form-check-label"
                               onClick={(e) => {
                                   this.fieldChangeHandler(name, formObj.val === "true" ? "false" : "true");
                               }}
                        >{formObj.label}</label>
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );
        }
        return null;
    }

    renderCheckBoxLicenseAgreementField(name) {
        const filteredArr = this.getFilteredArr(name);

        if (filteredArr.length > 0) {

            const formObj = filteredArr[0];

            return (
                <React.Fragment>
                    <div className="form-check mt-4">
                        <input className="form-check-input" type="checkbox"
                               checked={formObj.val === "true"}
                               data-name={name}
                               onChange={(e) => {
                                   this.fieldChangeHandler(name, formObj.val === "true" ? "false" : "true");
                               }}
                               value={true}/>
                        <label className="form-check-label"
                               onClick={(e) => {
                                   this.fieldChangeHandler(name, formObj.val === "true" ? "false" : "true");
                               }}
                        >я приймаю умови <span className="license-agreement-link" onClick={(e) => {
                            e.stopPropagation();
                            window.$("#LicenseAgreementModal").modal('show');
                        }}>Угоди користувача</span></label>
                    </div>
                    {this.renderFormFieldError(name)}
                </React.Fragment>
            );
        }
        return null;
    }

    renderSellerBlockFields() {
        let startIndex = null;
        this.props.appForm.formFields.forEach((formField, i) => {
            if (formField.node === 'title' && i !== 0) {
                startIndex = i + 1;
            }
        });

        if (startIndex === null) {
            return null;
        }

        return this.props.appForm.formFields.map((formField, i) => {
            if (i < startIndex) {
                return null;
            }

            if (formField.type === "text") {
                return (
                    <div
                        className={`col-md-${this.getFormFieldWidth(formField)} form-group ${formField.dependency ? this.renderDisplayOfDependant(formField.dependency) : ''}`}
                        key={i}>
                        {this.renderTextFormField(formField.name, false)}
                    </div>
                );
            } else if (formField.type === "number" || formField.type === "decimal") {
                return (
                    <div
                        className={`col-md-${this.getFormFieldWidth(formField)} form-group ${formField.dependency ? this.renderDisplayOfDependant(formField.dependency) : ''}`}
                        key={i}>
                        {this.renderTextFormField(formField.name, true)}
                    </div>
                );
            } else if (formField.type === "dropDown") {
                return (
                    <div
                        className={`col-md-${this.getFormFieldWidth(formField)} form-group ${formField.dependency ? this.renderDisplayOfDependant(formField.dependency) : ''}`}
                        key={i}>
                        {this.renderSelectFormField(formField.name)}
                    </div>
                );
            } else if (formField.type === "date") {
                return (
                    <div
                        className={`col-md-${this.getFormFieldWidth(formField)} form-group ${formField.dependency ? this.renderDisplayOfDependant(formField.dependency) : ''}`}
                        key={i}>
                        {this.renderDateFormField(formField.name)}
                    </div>
                );
            } else if (formField.type === "checkbox") {
                return (
                    <div className={`col-md-${this.getFormFieldWidth(formField)} form-group`} key={i}>
                        {this.renderCheckBoxFormField(formField.name)}
                    </div>
                );
            } else if (formField.type === "textarea") {
                return (
                    <div
                        className={`col-md-12 form-group ${formField.dependency ? this.renderDisplayOfDependant(formField.dependency) : ''}`}
                        key={i}>
                        {this.renderTextAreaFormField(formField.name)}
                    </div>
                );
            }
            return null;
        });

    }

    getFormFieldWidth(formFieldObj) {
        if (formFieldObj.width === 100) {
            return "12";
        }
        return "6";
    }

    renderDescription() {
        if (this.props.appForm.selectedIssueText && this.props.appForm.selectedIssueText !== "") {
            return (
                <div className="issue-text">
                    {this.props.appForm.selectedIssueText}
                </div>);
        }
        return null;
    }

    togglerChangeHandler(toggleObj) {

        const newVal = toggleObj.val === "true" ? "false" : "true";

        let callback = null;

        if (newVal === "true") {
            callback = function () {
                document.querySelector("#formToggler")
                    .scrollIntoView({behavior: "smooth"});
            }
        }
        this.props.setToStateFormToggle(newVal, callback);
    }

    renderToggleBlock() {

        if (this.props.appForm.formWithToggler) {

            const toggleObj = this.props.appForm.formFields[0];

            return (
                <div className="form-check mt-4" id="formToggler">
                    <input className="form-check-input" type="checkbox"
                           checked={toggleObj.val === "true"}
                           onChange={(e) => {
                               this.togglerChangeHandler(toggleObj);
                           }}
                           value={true}/>
                    <label className="form-check-label" onClick={(e) => {
                        this.togglerChangeHandler(toggleObj);
                    }}>
                        {toggleObj.text}
                    </label>
                </div>
            );
        }
        return null;
    }

    renderFormWithToggler() {
        if (this.props.appForm.formWithToggler) {

            const toggleObj = this.props.appForm.formFields[0];

            if (toggleObj.val === 'false') {
                return 'd-none';
            }
        }
        return '';
    }


    render() {
        if (this.props.appForm.fetchFieldsLoading) {
            return (<div className="text-center" style={{marginTop: '200px'}}><Spinner/></div>);
        } else if (this.props.appForm.fetchFieldsError) {
            return (
                <div className="text-danger text-center mt-5">
                    Помилка серверу
                </div>
            );
        } else if (this.props.appForm.formFields) {
            return (
                <motion.div key="MainModalBlockForm"
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 0.5}}
                            exit={{opacity: 0}}>
                    <div className="MainModalBlockForm">
                        <div className="issue-title">Ви обрали ситуацію:</div>
                        {this.renderDescription()}
                        <div className="text">
                            Залишилося зовсім трохи до вирішення Вашого питання... <br/>Для юридично
                            грамотного складання Листа до продавцеві або до контролюючого органу, Юридичному
                            онлайн-сервису
                            потрібні ще деякі дані – внесіть їх, будь ласка, в анкету нижче УКРАЇНСЬКОЮ мовою
                            максимально
                            точно. Юридичний онлайн-сервіс створить Лист і відправить Вам на e-mail. Вам тільки
                            залишиться
                            роздрукувати Лист, підписати та передати продавцю (порушнику Ваших прав)!
                        </div>
                        <div className="issue-description"
                             dangerouslySetInnerHTML={{__html: this.props.appForm.selectedIssueDescription}}/>

                        {this.renderToggleBlock()}

                        <div className={`form-block ${this.renderFormWithToggler()}`}>
                            <div className="buyer-block">
                                <div className="form-title">Дані покупця:</div>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        {this.renderTextFormField("last_name", false)}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        {this.renderTextFormField("first_name", false)}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        {this.renderTextFormField("middle_name", false)}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        {this.renderTextFormField("email", false)}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        {this.renderPhoneFormField("phone")}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        {this.renderSelectFormField("region")}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        {this.renderZipFormField("post_index")}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        {this.renderTextFormField("city_location", false)}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        {this.renderTextFormField("street", false)}
                                    </div>
                                    <div className="col-6 col-md-3 form-group">
                                        {this.renderTextFormField("build_number", false)}
                                    </div>
                                    <div className="col-6 col-md-3 form-group">
                                        {this.renderTextFormField("appartment_number", false)}
                                    </div>
                                </div>
                            </div>
                            <div className="seller-block">
                                <div className="form-title">Дані про товар та продавця:</div>
                                <div className="form-row">
                                    {this.renderSellerBlockFields()}
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            );
        }
        return null;
    }
}

export default MainModalBlockForm;