import React from 'react';
import Spinner from "../utility/Spinner";
import axios from "axios";
import getApiBaseUrlWithLang from "../utility/getApiBaseUrlWithLang";

class Slider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reviews: null,
            loading: false,
            error: null
        };

        this.renderCarouselItems = this.renderCarouselItems.bind(this);
        this.fetchReviews = this.fetchReviews.bind(this);
        this.renderIndicators = this.renderIndicators.bind(this);
    }

    componentDidMount() {

        this.fetchReviews();

        window.$(".carousel").carousel({
            interval: 10000
        });
    }

    fetchReviews() {

        this.setState({loading: true});

        axios.get(`${getApiBaseUrlWithLang()}/reviews`)
            .then((res) => {
                if (res.data.success === true) {
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            reviews: res.data.data,
                            loading: false,
                            error: null
                        };
                    });
                } else if (res.data.success === false) {
                    this.setState({error: "Помилка серверу", loading: false});
                }
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    }


    renderCarouselItems(){
        if(this.state.loading){
            return (<div className="text-center mt-5"><Spinner/></div>);
        } else if(this.state.reviews && this.state.reviews.length > 0){
            return this.state.reviews.map((review, i) => {
                return (
                    <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                        <div className="comment-text" dangerouslySetInnerHTML={{__html: review.message}} />
                        <div className="comment-author">
                            {review.name}
                        </div>
                    </div>
                );
            });
        }
        return null;
    }

    renderIndicators(){

        if(this.state.reviews && this.state.reviews.length > 0){
            return this.state.reviews.map((review, i) => {
                return (
                    <li key={i} data-target={`#${this.props.sliderId}`} data-slide-to={i} className={`${i === 0 ? 'active' : ''}`}></li>
                );
            });
        }
        return null;
    }

    render() {
        return (
            <div id={this.props.sliderId} className="carousel slide Slider" data-ride="carousel">
                <ol className="carousel-indicators">
                    {this.renderIndicators()}
                </ol>
                <div className="carousel-inner">
                    {this.renderCarouselItems()}
                </div>
                <a className="carousel-control-prev" href={`#${this.props.sliderId}`} role="button"
                   data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </a>
                <a className="carousel-control-next" href={`#${this.props.sliderId}`} role="button"
                   data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </a>
            </div>
        );
    }
}

export default Slider;